@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
} */

.dropdown {
  width: 100%;
  position: relative;
  margin: 0 auto;
}
.dropdown .dropdown-btn {
  padding: 10px;

  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.6);
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown .dropdown-content {
  position: absolute;
  top: 110%;
  overflow-x: scroll;
  height: 200px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.6);
  font-weight: bold;
  color: #333;
  width: 100%;
}
.deleteBTN {
  opacity: 1;
  position: -webkit-sticky;
  position: sticky;
  left: 30%;
}
tr:hover .deleteBTN {
  opacity: 1;
  cursor: pointer;
}
.delete-btn {
  cursor: pointer;
  background-color: #e74c3c;
  border: 0;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  padding: 2px 5px;
  position: relative;
  top: 50%;
  left: 0;
  -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%);
  opacity: 1;
  transition: opacity 0.3s ease;
}

td:hover .delete-btn {
  opacity: 1;
}
.dropdown .dropdown-content .dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.dropdown .dropdown-content .dropdown-item:hover {
  background-color: #45d677;
}

.date {
  background: #fff
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    97% 50% no-repeat;
}
.tables-css {
  cursor: pointer;
}
.modal-css:hover {
  color: white !important;
}
.tables-css:hover {
  background-color: #22598e;
  color: white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.inner_page {
  /* margin-left: 283px; */
  width: 82%;
  margin-left: 18%;
}
.billingIcon {
  position: fixed;
  left: 96%;
  cursor: pointer;
  color: darkblue;
}

.member {
  margin-left: 18%;
}
@media (max-width: 820px) {
  .inner_page {
    margin-left: 0px;
    width: 100%;
    padding: 10px 15px;
  }
  td {
    font-size: 10px !important;
  }
  th {
    font-size: 10px !important;
  }

  .member {
    margin-left: 0px;
    padding: 0px !important;
  }
}

@media (max-width: 700px) {
  .inner_page {
    padding: 0px;
  }
  .billingIcon {
    position: absolute;
    left: 27%;
    cursor: pointer;
    color: darkblue;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 20%;
}

@media (max-width: 430px) {
  .loader {
    margin-top: 46%;
    left: 30%;
  }
}
@media (min-width: 500px) and (max-width: 830px) {
  .loader {
    margin-top: 29%;
    top: 20%;
    left: 40%;
  }
}

.img-round {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 50%;
}
.img-round > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* new */

/* 



.rwd-table {
  margin: auto;
  padding: 0px;
  width: 100%;

  max-width: 100%;
  border-collapse: separate;
}

.rwd-table tr:first-child {
  border-top: none;
  background: rgb(10, 127, 182);
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #ddd;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ccc;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  background: white !important;
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: black;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #fff;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #fff;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}
 */

.modal-content {
  background-color: white;
}
modal-confirm {
  color: #636363;
  width: 300px;
}
@media screen and (max-width: 800px) {
  .modal-confirm {
    color: #636363;
    width: 340px;
  }
}
.modal-confirm .modal-content {
  padding: 10px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
  padding: 10px 15px 25px;
}
.modal-confirm .modal-footer a {
  color: #999;
}
.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
}
.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 45px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}
.modal-confirm .btn-secondary {
  background: #c1c1c1;
}
.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}
.modal-confirm .btn-danger {
  background: #000000;
}
.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #0f0f0fc4;
}

.searchable {
  width: 300px;
  float: left;
  margin: 0 15px;
}

.searchable input {
  width: 100%;
  height: 50px;
  font-size: 18px;
  padding: 10px; /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  display: block;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.searchable ul {
  list-style-type: none;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  border: 1px solid #add8e6;
  border-top: none;
  max-height: 180px;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
}

.searchable ul li {
  padding: 7px 9px;
  border-bottom: 1px solid #e1e1e1;
  cursor: pointer;
  color: #6e6e6e;
}

.searchable ul li.selected {
  background-color: #e8e8e8;
  color: #333;
}
/* 
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@500&family=Roboto:wght@900&display=swap");
body{
  font-family: 'Roboto', sans-serif !important;
  font-weight: 800;
} */

.body-fixed {
  overflow-y: hidden;
}

.vertical-nav {
  /* min-width: 16rem;
    width: 16rem; */
  width: 18% !important;
  height: 100vh;
  position: fixed;
  overflow-x: scroll !important;
  top: 0;
  left: 0;
  /* box-shadow: 3px 3px 10px rgba(165, 163, 163, 0.1);
    transition: all 0.4s; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 10;
}
@media (max-width: 900px) {
  .vertical-nav {
    min-width: 16rem;
    width: 16rem;
  }
}
.page-content {
  transition: all 0.4s;
  margin-left: 20%;
  /* margin-top: 88px; */
  margin-top: 83px;
  /* margin-left: 21%;
    margin-top: 6%; */
}
@media (max-width: 700px) {
  .page-content {
    padding-top: 0rem;
    /* padding-left: 10px; */
  }
}
@media (min-width: 700px) and (max-width: 820px) {
  .page-content {
    margin-top: 88px !important;
  }
  .inner {
    margin-left: 0 !important;
  }
}

#sidebar.active {
  margin-left: -17rem;
  max-height: 100%;
  overflow: hidden !important;
}

#content.active {
  width: 100%;
  margin: 0;
}

.separator {
  margin: 3rem 0;
  border-bottom: 1px dashed #fff;
}

.text-uppercase {
  letter-spacing: 0.1em;
}
.text-gray {
  color: #aaa;
}
.nav-link {
  text-transform: capitalize;
}

.nav_item i {
  cursor: pointer;
  font-weight: 600 !important;
  font-size: 13px;
}

.text-primary {
  color: #7579e7 !important;
}
@media (max-width: 820px) {
  #sidebar {
    margin-left: -17rem;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
    margin: 0;
  }
  #content.active {
    margin-left: 17rem;
    width: calc(100% - 17rem);
  }
}

/* Top Bar */

.navbar {
  padding: 0.2rem 1.5rem;
}
@media (max-width: 800px) {
  .navbar {
    padding: 0rem 1.5rem;
  }
}

@media (max-width: 960px) {
  .navbar > .container-fluid {
    justify-content: flex-end;
  }
}

.o-topbar1 {
  /* -webkit-box-shadow: 0 1px 1px rgba(196, 194, 202, 0.23); */
  background: #fff;
  /* box-shadow: 0 1px 1px rgba(196, 194, 202, 0.23); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  left: 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 944;
}

.o-topbar1 .o-topbar1 .container {
  position: relative;
}
@media (min-width: 768px) {
  .o-topbar1 {
    position: fixed;
  }
}
.o-topbar1__brand img {
  height: 30px;
  margin-left: 24px;
  width: auto;
}
@media screen and (min-width: 480px) {
  .o-topbar1__brand img {
    height: 45px;
  }
}
@media (min-width: 768px) {
  .o-topbar1__brand img {
    height: auto;
    margin-left: 0;
  }
}
.o-topbar1__nav {
  font-size: 1rem;
}
.o-topbar1__navLink {
  color: #0062cc !important;
  font-size: 1.2rem;
  padding-left: 20px !important;
  padding-right: 20px !important;
  transition: all 0.3s ease;
}
.o-topbar1__navLink:hover {
  color: #3395ff !important;
}
.o-topbar1__navCta {
  position: absolute;
  right: 80px;
  top: 8px;
}
@media (min-width: 992px) {
  .o-topbar1__navCta {
    position: relative;
    right: auto;
    top: 0;
  }
}

.kubers_text {
  position: absolute;
  top: 27px;
  left: 144px;
  font-weight: 600;
  color: #3f51b5;
  opacity: 0.9;
}
@media (max-width: 1100px) {
  .kubers_text {
    display: none !important;
  }
}
.o-topbar1__ctaButton {
  border-radius: 26px;
  float: right;
  font-size: 1rem;
  font-weight: bold;
  height: 28px;
  letter-spacing: 0;
  line-height: 1.5;
  margin: 2px 0 0 20px;
  min-width: 110px;
  padding: 0 7px;
}
@media screen and (min-width: 480px) {
  .o-topbar1__ctaButton {
    font-size: 1.1rem;
    height: 34px;
    line-height: 1.8;
    min-width: 145px;
  }
}
@media (min-width: 768px) {
  .o-topbar1__ctaButton {
    border-radius: 20px;
    height: 38px;
    line-height: 2;
  }
}
.o-topbar1__navbarToggler {
  border: none;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 10px;
}
@media (min-width: 576px) {
  .o-topbar1__navbarToggler {
    top: 12px;
  }
}
.o-topbar1__navbarToggler:focus {
  outline-style: none;
}

.a-navbarToggler1 {
  -moz-transform: rotate(0deg);
  -moz-transition: 0.5s ease-in-out;
  -o-transform: rotate(0deg);
  -o-transition: 0.5s ease-in-out;
  -webkit-transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  cursor: pointer;
  display: block;
  height: 20px;
  margin: 0px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 24px;
}
.a-navbarToggler1 span {
  -moz-transform: rotate(0deg);
  -moz-transition: 0.25s ease-in-out;
  -o-transform: rotate(0deg);
  -o-transition: 0.25s ease-in-out;
  -webkit-transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  background: #495057;
  border-radius: 9px;
  display: block;
  height: 3px;
  left: 0;
  opacity: 1;
  position: absolute;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  width: 100%;
}
.a-navbarToggler1 span:nth-child(1) {
  top: 0px;
}
.a-navbarToggler1 span:nth-child(2) {
  top: 8px;
}
@media screen and (min-width: 480px) {
  .a-navbarToggler1 span:nth-child(2) {
    top: 10px;
  }
}
.a-navbarToggler1 span:nth-child(3) {
  top: 16px;
}
@media screen and (min-width: 480px) {
  .a-navbarToggler1 span:nth-child(3) {
    top: 20px;
  }
}
.a-navbarToggler1.open span:nth-child(1) {
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  top: 11px;
  transform: rotate(135deg);
}
.a-navbarToggler1.open span:nth-child(2) {
  left: -60px;
  opacity: 0;
}
.a-navbarToggler1.open span:nth-child(3) {
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  top: 11px;
  transform: rotate(-135deg);
}

/* Toggle button */

/* Bootstrap Nav new  */

.avatar {
  display: inline-block;
  position: relative;
  width: 3rem;
  height: 3rem;
  text-align: center;
  border: #dee2e6;
  border-radius: 50%;
  background: #fff;
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  line-height: 3rem;
}

.avatar.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.7875rem;
  white-space: nowrap;
}
.input-group-navbar {
  padding-top: 1.3rem;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group-navbar .form-control {
  background: #e9ecef;
  border: none;
  box-shadow: none;
  font-size: 0.7875rem;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
vi .input-group-navbar .btn {
  background: #e9ecef;
}

.input-group .btn:focus {
  z-index: 3;
  outline: none;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 1.5rem;
  font-size: 0.7875rem;
  border-radius: 0.2rem;
}

.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: absolute !important;
  min-width: 10rem;
  padding: 0.5rem 0;
  font-size: 0.8rem;
  color: #6c757d;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.25rem;
}
.dropdown-menu-animated.show {
  top: 100% !important;
}
.dropdown-menu-animated.dropdown-menu-end {
  right: 0 !important;
  left: auto !important;
}
.dropdown-menu-animated {
  position: absolute;
  margin: 0;
  -webkit-animation-name: AnimateDropdown;
          animation-name: AnimateDropdown;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  z-index: 1000;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #495057;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover {
  color: #42484e;
  text-decoration: none;
  background-color: #e9ecef;
}

.icon {
  display: flex;
  flex-shrink: 0;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.icon.icon-sm {
  width: 1.7rem;
  height: 1.7rem;
  font-size: 0.7rem;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

/* Flat bar navigation */

.flatbar {
  width: auto;
}

.inner {
  background-color: #2980b9;
  /* margin: 0 6px; */
  /* padding: 0 14px 0 2%; */
  /* margin-left: -4%; */
}

.nav_bar {
  margin: 0;
  padding: 0;
}

.nav_section {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  /* height: 44px; */
  z-index: 2;
  padding-top: 4px;
  line-height: 30px;
}

.nav_link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 100%;
  padding: 5px 14px;
  font-size: 15px;
  font-family: helvetica;
  font-weight: 700;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
}

.nav_link:hover {
  color: white;
}

.nav_section:hover:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  transition: all 0.1s ease-out;
}

.nav_section:before {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 50%;
  left: 50%;
  top: 100%;
  background-color: #3498db;
  transition: all 0.1s ease-in;
}

.nav_section.dropdown .dropdown-menu .dropdown-item {
  padding: 0rem 1rem;
  transition: all 0.2s ease-in-out;
}

.nav_section.dropdown .dropdown-menu {
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
}

.nav_section.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #2980b9;
  border-radius: 0.125rem;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in-out;
  color: white;
}

.search_bar {
  width: 11rem;
  transition: 1s ease-in-out;
}
.times_icon {
  position: absolute;
  bottom: 10px;
  right: 3px;
  cursor: pointer;
  color: grey;
}

.calendar {
  background-color: #fff;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
}
.form-group label {
  opacity: 0.7;
}
.form-group input {
  border: 1px solid #dde3e6;
  border-radius: 6px;
}
.form-control:focus {
  box-shadow: none;
  border-color: #dde3e6;
}
.hr-primary {
  height: 4px;
  margin-left: 15px;
  margin-bottom: -3px;
  border-radius: 0.4rem;
  background-image: -webkit-linear-gradient(
    left,
    rgba(66, 133, 244, 0.8),
    rgba(66, 133, 244, 0.6),
    rgba(0, 0, 0, 0)
  );
}

/* Btn Gradient */

.Login_containerWorld__3xdQh {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 450px;
}

#Login_inputStyle__2r8rJ {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.Login_formDiv__S1U7v {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 40px;
  height: 100%;
  text-align: center;
}
.Login_buttonClass__2s4im {
  border-radius: 20px;
  border: 1px solid #2ba7ff;
  background-color: #2ba7ff;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 50px;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
  transition: -webkit-transform 50ms ease-in;
  transition: transform 50ms ease-in;
  transition: transform 50ms ease-in, -webkit-transform 50ms ease-in;
}

.Login_overlayPanel__Uee1O {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 100%;
}

.Login_bodyClass__2BP7t {
  background: #c4d7fb;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
}

@media screen and (max-width: 800px) {
  .Login_signin__1YyQU {
    overflow: hidden;
  }
  .Login_buttonClass__2s4im {
    border-radius: 20px;
    border: 1px solid #2ba7ff;
    background-color: #2ba7ff;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    padding: 15px 50px;
    letter-spacing: 1px;
    cursor: pointer;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  }

  .Login_bodyClass__2BP7t {
    background: #c4d7fb;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    min-height: 95vh;
  }

  .Login_formDiv__S1U7v {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: -15%;
    justify-content: center;
    flex-direction: column;
    padding: 0px 50px;
    padding-bottom: 30px;
    height: 500px;
    text-align: center;
  }
}
.Login_signin__bg__2JiuI {
  min-height: 100%;
  min-width: 1024px;
  -webkit-filter: brightness(65%);
          filter: brightness(65%);
  width: 100%;
  height: auto;
}
@media screen and (max-width: 800px) {
  .Login_signin__bg__2JiuI {
    left: 50%;
    margin-left: -512px;
  }
}
.Login_signin__container__3hvOM {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .Login_signin__container__3hvOM {
    width: 100%;
  }
}
.Login_signin__shadow__z2njy {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)); */
  margin-top: 120px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  height: auto;
  width: 390px;
  position: absolute;
  top: 0;
}

@media screen and (max-width: 800px) {
  .Login_signin__shadow__z2njy {
    /* margin-top: 150px; */
    width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    margin-bottom: 20px;
  }
}
.Login_signin__title__3lteN {
  color: black;
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 2rem;
}
.Login_signin__btn_container__ng7Cz {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Login_signin__btn__AXtMZ {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  width: 70%;
}
.Login_signin__google_icon__TquIq {
  font-size: 1.2rem;
  height: 50%;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 8px;
}
.Login_signin__option__35-PC {
  width: 100%;
  display: flex;
  justify-content: center;
}
.Login_signin__option_newuser__9pW0V {
  color: black;
  text-align: center;
  margin-right: 5px;
}
.Login_signin__option_link__Myjzt {
  color: black;
  text-decoration: none;
  margin-bottom: 10px;
  text-decoration: none !important;
}
.Login_signin__option_link__Myjzt:hover {
  text-decoration: underline;
  color: black;
  opacity: 0.8;
}

.Login_user_forgot__1rgdw {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.Login_forgot_password__12c-6 {
  color: black;
  text-decoration: none;
  margin-bottom: 30px;
  text-decoration: none !important;
}
.Login_forgot_password__12c-6:hover {
  text-decoration: underline;
  color: black;
  opacity: 0.8;
}

.Login_linkedin__1rhOl {
  min-width: 130px;
  width: auto;
  height: 40px;
  line-height: 40px;
  padding: 0 22px 0 22px;
  font-size: 14.5px;
  background-color: #3c44bc;
  font-weight: 600;
  font-family: helvetica;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.Login_linkedin__1rhOl:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.Login_group__3UuCj {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.Login_group__3UuCj .Login_form_input__11KyW {
  background: none;
  color: #343a40;
  font-size: 1rem;
  padding: 10px 10px 10px 7px;
  display: block;
  width: 70%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  margin: 15px 0;
}
.Login_group__3UuCj .Login_form_input__11KyW:focus {
  outline: none;
}
.Login_group__3UuCj .Login_form_input__11KyW:focus ~ .Login_form_input_label__3bMHx {
  top: -10px;
  left: 60px;
  font-size: 12px;
  color: #343a3f;
}
.Login_group__3UuCj input[type="password"] {
  letter-spacing: 0.3em;
}
.Login_group__3UuCj .Login_form_input_label__3bMHx {
  color: #343a3f;
  opacity: 1;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 60px;
  top: -10px;
  transition: 300ms ease all;
}
.Login_group__3UuCj .Login_form_input_label__3bMHx.Login_shrink__1v9wt {
  top: -10px;
  left: 60px;
  font-size: 12px;
  color: #343a3f;
}

.Login_custom_button__3yAt8 {
  min-width: 130px;
  width: auto;
  height: 40px;
  line-height: 40px;
  padding: 0 22px 0 22px;
  font-size: 14.5px;
  background-color: #4267b2;
  border-color: #4267b2;
  font-weight: 600;
  font-family: helvetica;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.Login_custom_button__3yAt8:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.Login_custom_button__3yAt8:active {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.Login_custom_button__3yAt8.Login_signin__1YyQU {
  background-color: #fff;
  color: black;
}
.Login_custom_button__3yAt8.Login_signin__1YyQU:hover {
  border: none;
}
.Login_custom_button__3yAt8 .Login_custom_button__3yAt8:active {
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.Login_errorMessage__1OCkv {
  color: red;
  font-weight: bolder;
  opacity: 0.8;
  margin-top: -10px;
  margin-bottom: 20px;
  /* margin-top: -20px;
    margin-left: 15%; */
}

.Login_btnerrorMessage__11MmC {
  color: yellow;
}

