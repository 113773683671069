@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
/* .dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.dropdown .selected-value {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
} */

.dropdown {
  width: 100%;
  position: relative;
  margin: 0 auto;
}
.dropdown .dropdown-btn {
  padding: 10px;

  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.6);
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown .dropdown-content {
  position: absolute;
  top: 110%;
  overflow-x: scroll;
  height: 200px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.6);
  font-weight: bold;
  color: #333;
  width: 100%;
}
.deleteBTN {
  opacity: 1;
  position: sticky;
  left: 30%;
}
tr:hover .deleteBTN {
  opacity: 1;
  cursor: pointer;
}
.delete-btn {
  cursor: pointer;
  background-color: #e74c3c;
  border: 0;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  padding: 2px 5px;
  position: relative;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
  opacity: 1;
  transition: opacity 0.3s ease;
}

td:hover .delete-btn {
  opacity: 1;
}
.dropdown .dropdown-content .dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.dropdown .dropdown-content .dropdown-item:hover {
  background-color: #45d677;
}

.date {
  background: #fff
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    97% 50% no-repeat;
}
.tables-css {
  cursor: pointer;
}
.modal-css:hover {
  color: white !important;
}
.tables-css:hover {
  background-color: #22598e;
  color: white;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

.inner_page {
  /* margin-left: 283px; */
  width: 82%;
  margin-left: 18%;
}
.billingIcon {
  position: fixed;
  left: 96%;
  cursor: pointer;
  color: darkblue;
}

.member {
  margin-left: 18%;
}
@media (max-width: 820px) {
  .inner_page {
    margin-left: 0px;
    width: 100%;
    padding: 10px 15px;
  }
  td {
    font-size: 10px !important;
  }
  th {
    font-size: 10px !important;
  }

  .member {
    margin-left: 0px;
    padding: 0px !important;
  }
}

@media (max-width: 700px) {
  .inner_page {
    padding: 0px;
  }
  .billingIcon {
    position: absolute;
    left: 27%;
    cursor: pointer;
    color: darkblue;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 20%;
}

@media (max-width: 430px) {
  .loader {
    margin-top: 46%;
    left: 30%;
  }
}
@media (min-width: 500px) and (max-width: 830px) {
  .loader {
    margin-top: 29%;
    top: 20%;
    left: 40%;
  }
}

.img-round {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 50%;
}
.img-round > img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* new */

/* 



.rwd-table {
  margin: auto;
  padding: 0px;
  width: 100%;

  max-width: 100%;
  border-collapse: separate;
}

.rwd-table tr:first-child {
  border-top: none;
  background: rgb(10, 127, 182);
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #ddd;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ccc;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  background: white !important;
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: black;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #fff;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #fff;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}
 */

.modal-content {
  background-color: white;
}
modal-confirm {
  color: #636363;
  width: 300px;
}
@media screen and (max-width: 800px) {
  .modal-confirm {
    color: #636363;
    width: 340px;
  }
}
.modal-confirm .modal-content {
  padding: 10px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
  padding: 10px 15px 25px;
}
.modal-confirm .modal-footer a {
  color: #999;
}
.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
}
.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 45px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}
.modal-confirm .btn-secondary {
  background: #c1c1c1;
}
.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}
.modal-confirm .btn-danger {
  background: #000000;
}
.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #0f0f0fc4;
}

.searchable {
  width: 300px;
  float: left;
  margin: 0 15px;
}

.searchable input {
  width: 100%;
  height: 50px;
  font-size: 18px;
  padding: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  display: block;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.searchable ul {
  list-style-type: none;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  border: 1px solid #add8e6;
  border-top: none;
  max-height: 180px;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
}

.searchable ul li {
  padding: 7px 9px;
  border-bottom: 1px solid #e1e1e1;
  cursor: pointer;
  color: #6e6e6e;
}

.searchable ul li.selected {
  background-color: #e8e8e8;
  color: #333;
}
/* 
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@500&family=Roboto:wght@900&display=swap");
body{
  font-family: 'Roboto', sans-serif !important;
  font-weight: 800;
} */
