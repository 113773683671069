.containerWorld {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 450px;
}

#inputStyle {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.formDiv {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 40px;
  height: 100%;
  text-align: center;
}
.buttonClass {
  border-radius: 20px;
  border: 1px solid #2ba7ff;
  background-color: #2ba7ff;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 50px;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: uppercase;
  transition: transform 50ms ease-in;
}

.overlayPanel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 100%;
}

.bodyClass {
  background: #c4d7fb;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
}

@media screen and (max-width: 800px) {
  .signin {
    overflow: hidden;
  }
  .buttonClass {
    border-radius: 20px;
    border: 1px solid #2ba7ff;
    background-color: #2ba7ff;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    padding: 15px 50px;
    letter-spacing: 1px;
    cursor: pointer;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }

  .bodyClass {
    background: #c4d7fb;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    min-height: 95vh;
  }

  .formDiv {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: -15%;
    justify-content: center;
    flex-direction: column;
    padding: 0px 50px;
    padding-bottom: 30px;
    height: 500px;
    text-align: center;
  }
}
.signin__bg {
  min-height: 100%;
  min-width: 1024px;
  filter: brightness(65%);
  width: 100%;
  height: auto;
}
@media screen and (max-width: 800px) {
  .signin__bg {
    left: 50%;
    margin-left: -512px;
  }
}
.signin__container {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .signin__container {
    width: 100%;
  }
}
.signin__shadow {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)); */
  margin-top: 120px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  height: auto;
  width: 390px;
  position: absolute;
  top: 0;
}

@media screen and (max-width: 800px) {
  .signin__shadow {
    /* margin-top: 150px; */
    width: 330px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    margin-bottom: 20px;
  }
}
.signin__title {
  color: black;
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 2rem;
}
.signin__btn_container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.signin__btn {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  width: 70%;
}
.signin__google_icon {
  font-size: 1.2rem;
  height: 50%;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 8px;
}
.signin__option {
  width: 100%;
  display: flex;
  justify-content: center;
}
.signin__option_newuser {
  color: black;
  text-align: center;
  margin-right: 5px;
}
.signin__option_link {
  color: black;
  text-decoration: none;
  margin-bottom: 10px;
  text-decoration: none !important;
}
.signin__option_link:hover {
  text-decoration: underline;
  color: black;
  opacity: 0.8;
}

.user_forgot {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.forgot_password {
  color: black;
  text-decoration: none;
  margin-bottom: 30px;
  text-decoration: none !important;
}
.forgot_password:hover {
  text-decoration: underline;
  color: black;
  opacity: 0.8;
}

.linkedin {
  min-width: 130px;
  width: auto;
  height: 40px;
  line-height: 40px;
  padding: 0 22px 0 22px;
  font-size: 14.5px;
  background-color: #3c44bc;
  font-weight: 600;
  font-family: helvetica;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.linkedin:hover {
  transform: translateY(-2px);
}

.group {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.group .form_input {
  background: none;
  color: #343a40;
  font-size: 1rem;
  padding: 10px 10px 10px 7px;
  display: block;
  width: 70%;
  border: 1px solid #ced4da;
  border-radius: 3px;
  margin: 15px 0;
}
.group .form_input:focus {
  outline: none;
}
.group .form_input:focus ~ .form_input_label {
  top: -10px;
  left: 60px;
  font-size: 12px;
  color: #343a3f;
}
.group input[type="password"] {
  letter-spacing: 0.3em;
}
.group .form_input_label {
  color: #343a3f;
  opacity: 1;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 60px;
  top: -10px;
  transition: 300ms ease all;
}
.group .form_input_label.shrink {
  top: -10px;
  left: 60px;
  font-size: 12px;
  color: #343a3f;
}

.custom_button {
  min-width: 130px;
  width: auto;
  height: 40px;
  line-height: 40px;
  padding: 0 22px 0 22px;
  font-size: 14.5px;
  background-color: #4267b2;
  border-color: #4267b2;
  font-weight: 600;
  font-family: helvetica;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.custom_button:hover {
  transform: translateY(-2px);
}
.custom_button:active {
  transform: translateY(-1px);
}
.custom_button.signin {
  background-color: #fff;
  color: black;
}
.custom_button.signin:hover {
  border: none;
}
.custom_button .custom_button:active {
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.errorMessage {
  color: red;
  font-weight: bolder;
  opacity: 0.8;
  margin-top: -10px;
  margin-bottom: 20px;
  /* margin-top: -20px;
    margin-left: 15%; */
}

.btnerrorMessage {
  color: yellow;
}
