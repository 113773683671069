.body-fixed {
  overflow-y: hidden;
}

.vertical-nav {
  /* min-width: 16rem;
    width: 16rem; */
  width: 18% !important;
  height: 100vh;
  position: fixed;
  overflow-x: scroll !important;
  top: 0;
  left: 0;
  /* box-shadow: 3px 3px 10px rgba(165, 163, 163, 0.1);
    transition: all 0.4s; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 10;
}
@media (max-width: 900px) {
  .vertical-nav {
    min-width: 16rem;
    width: 16rem;
  }
}
.page-content {
  transition: all 0.4s;
  margin-left: 20%;
  /* margin-top: 88px; */
  margin-top: 83px;
  /* margin-left: 21%;
    margin-top: 6%; */
}
@media (max-width: 700px) {
  .page-content {
    padding-top: 0rem;
    /* padding-left: 10px; */
  }
}
@media (min-width: 700px) and (max-width: 820px) {
  .page-content {
    margin-top: 88px !important;
  }
  .inner {
    margin-left: 0 !important;
  }
}

#sidebar.active {
  margin-left: -17rem;
  max-height: 100%;
  overflow: hidden !important;
}

#content.active {
  width: 100%;
  margin: 0;
}

.separator {
  margin: 3rem 0;
  border-bottom: 1px dashed #fff;
}

.text-uppercase {
  letter-spacing: 0.1em;
}
.text-gray {
  color: #aaa;
}
.nav-link {
  text-transform: capitalize;
}

.nav_item i {
  cursor: pointer;
  font-weight: 600 !important;
  font-size: 13px;
}

.text-primary {
  color: #7579e7 !important;
}
@media (max-width: 820px) {
  #sidebar {
    margin-left: -17rem;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
    margin: 0;
  }
  #content.active {
    margin-left: 17rem;
    width: calc(100% - 17rem);
  }
}

/* Top Bar */

.navbar {
  padding: 0.2rem 1.5rem;
}
@media (max-width: 800px) {
  .navbar {
    padding: 0rem 1.5rem;
  }
}

@media (max-width: 960px) {
  .navbar > .container-fluid {
    justify-content: flex-end;
  }
}

.o-topbar1 {
  /* -webkit-box-shadow: 0 1px 1px rgba(196, 194, 202, 0.23); */
  background: #fff;
  /* box-shadow: 0 1px 1px rgba(196, 194, 202, 0.23); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  left: 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 944;
}

.o-topbar1 .o-topbar1 .container {
  position: relative;
}
@media (min-width: 768px) {
  .o-topbar1 {
    position: fixed;
  }
}
.o-topbar1__brand img {
  height: 30px;
  margin-left: 24px;
  width: auto;
}
@media screen and (min-width: 480px) {
  .o-topbar1__brand img {
    height: 45px;
  }
}
@media (min-width: 768px) {
  .o-topbar1__brand img {
    height: auto;
    margin-left: 0;
  }
}
.o-topbar1__nav {
  font-size: 1rem;
}
.o-topbar1__navLink {
  color: #0062cc !important;
  font-size: 1.2rem;
  padding-left: 20px !important;
  padding-right: 20px !important;
  transition: all 0.3s ease;
}
.o-topbar1__navLink:hover {
  color: #3395ff !important;
}
.o-topbar1__navCta {
  position: absolute;
  right: 80px;
  top: 8px;
}
@media (min-width: 992px) {
  .o-topbar1__navCta {
    position: relative;
    right: auto;
    top: 0;
  }
}

.kubers_text {
  position: absolute;
  top: 27px;
  left: 144px;
  font-weight: 600;
  color: #3f51b5;
  opacity: 0.9;
}
@media (max-width: 1100px) {
  .kubers_text {
    display: none !important;
  }
}
.o-topbar1__ctaButton {
  border-radius: 26px;
  float: right;
  font-size: 1rem;
  font-weight: bold;
  height: 28px;
  letter-spacing: 0;
  line-height: 1.5;
  margin: 2px 0 0 20px;
  min-width: 110px;
  padding: 0 7px;
}
@media screen and (min-width: 480px) {
  .o-topbar1__ctaButton {
    font-size: 1.1rem;
    height: 34px;
    line-height: 1.8;
    min-width: 145px;
  }
}
@media (min-width: 768px) {
  .o-topbar1__ctaButton {
    border-radius: 20px;
    height: 38px;
    line-height: 2;
  }
}
.o-topbar1__navbarToggler {
  border: none;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 10px;
}
@media (min-width: 576px) {
  .o-topbar1__navbarToggler {
    top: 12px;
  }
}
.o-topbar1__navbarToggler:focus {
  outline-style: none;
}

.a-navbarToggler1 {
  -moz-transform: rotate(0deg);
  -moz-transition: 0.5s ease-in-out;
  -o-transform: rotate(0deg);
  -o-transition: 0.5s ease-in-out;
  -webkit-transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  cursor: pointer;
  display: block;
  height: 20px;
  margin: 0px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  width: 24px;
}
.a-navbarToggler1 span {
  -moz-transform: rotate(0deg);
  -moz-transition: 0.25s ease-in-out;
  -o-transform: rotate(0deg);
  -o-transition: 0.25s ease-in-out;
  -webkit-transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  background: #495057;
  border-radius: 9px;
  display: block;
  height: 3px;
  left: 0;
  opacity: 1;
  position: absolute;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  width: 100%;
}
.a-navbarToggler1 span:nth-child(1) {
  top: 0px;
}
.a-navbarToggler1 span:nth-child(2) {
  top: 8px;
}
@media screen and (min-width: 480px) {
  .a-navbarToggler1 span:nth-child(2) {
    top: 10px;
  }
}
.a-navbarToggler1 span:nth-child(3) {
  top: 16px;
}
@media screen and (min-width: 480px) {
  .a-navbarToggler1 span:nth-child(3) {
    top: 20px;
  }
}
.a-navbarToggler1.open span:nth-child(1) {
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  top: 11px;
  transform: rotate(135deg);
}
.a-navbarToggler1.open span:nth-child(2) {
  left: -60px;
  opacity: 0;
}
.a-navbarToggler1.open span:nth-child(3) {
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  top: 11px;
  transform: rotate(-135deg);
}

/* Toggle button */

/* Bootstrap Nav new  */

.avatar {
  display: inline-block;
  position: relative;
  width: 3rem;
  height: 3rem;
  text-align: center;
  border: #dee2e6;
  border-radius: 50%;
  background: #fff;
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  line-height: 3rem;
}

.avatar.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.7875rem;
  white-space: nowrap;
}
.input-group-navbar {
  padding-top: 1.3rem;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group-navbar .form-control {
  background: #e9ecef;
  border: none;
  box-shadow: none;
  font-size: 0.7875rem;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
vi .input-group-navbar .btn {
  background: #e9ecef;
}

.input-group .btn:focus {
  z-index: 3;
  outline: none;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 1.5rem;
  font-size: 0.7875rem;
  border-radius: 0.2rem;
}

.dropdown-menu {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: absolute !important;
  min-width: 10rem;
  padding: 0.5rem 0;
  font-size: 0.8rem;
  color: #6c757d;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.25rem;
}
.dropdown-menu-animated.show {
  top: 100% !important;
}
.dropdown-menu-animated.dropdown-menu-end {
  right: 0 !important;
  left: auto !important;
}
.dropdown-menu-animated {
  position: absolute;
  margin: 0;
  animation-name: AnimateDropdown;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  z-index: 1000;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #495057;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover {
  color: #42484e;
  text-decoration: none;
  background-color: #e9ecef;
}

.icon {
  display: flex;
  flex-shrink: 0;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.icon.icon-sm {
  width: 1.7rem;
  height: 1.7rem;
  font-size: 0.7rem;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

/* Flat bar navigation */

.flatbar {
  width: auto;
}

.inner {
  background-color: #2980b9;
  /* margin: 0 6px; */
  /* padding: 0 14px 0 2%; */
  /* margin-left: -4%; */
}

.nav_bar {
  margin: 0;
  padding: 0;
}

.nav_section {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  /* height: 44px; */
  z-index: 2;
  padding-top: 4px;
  line-height: 30px;
}

.nav_link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 100%;
  padding: 5px 14px;
  font-size: 15px;
  font-family: helvetica;
  font-weight: 700;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
}

.nav_link:hover {
  color: white;
}

.nav_section:hover:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  -webkit-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

.nav_section:before {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 50%;
  left: 50%;
  top: 100%;
  background-color: #3498db;
  -webkit-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.nav_section.dropdown .dropdown-menu .dropdown-item {
  padding: 0rem 1rem;
  transition: all 0.2s ease-in-out;
}

.nav_section.dropdown .dropdown-menu {
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
}

.nav_section.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #2980b9;
  border-radius: 0.125rem;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in-out;
  color: white;
}

.search_bar {
  width: 11rem;
  transition: 1s ease-in-out;
}
.times_icon {
  position: absolute;
  bottom: 10px;
  right: 3px;
  cursor: pointer;
  color: grey;
}

.calendar {
  background-color: #fff;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
}
.form-group label {
  opacity: 0.7;
}
.form-group input {
  border: 1px solid #dde3e6;
  border-radius: 6px;
}
.form-control:focus {
  box-shadow: none;
  border-color: #dde3e6;
}
.hr-primary {
  height: 4px;
  margin-left: 15px;
  margin-bottom: -3px;
  border-radius: 0.4rem;
  background-image: -webkit-linear-gradient(
    left,
    rgba(66, 133, 244, 0.8),
    rgba(66, 133, 244, 0.6),
    rgba(0, 0, 0, 0)
  );
}

/* Btn Gradient */
